<template>
    <div class="modal-input">
        <div class="head flex-row flex-between items-start">
            <div class="title" :class="titleClass" v-html="$translate(title)" />
        </div>
        <div v-if="inputs" class="inputs">
            <div class="form-control" :key="idx" v-for="(input, idx) in inputs">
                <label v-html="$translate(input.label)" />
                <input
                    :class="`modal-input-${idx}`"
                    v-if="input.type !== 'textarea'"
                    @input="input.text = inputChecker($event.target.value)"
                    :value="input.text"
                    :placeholder="$translate(input.placeholder)"
                />
                <textarea
                    v-else
                    @input="input.text = inputChecker($event.target.value)"
                    :value="input.text"
                    :placeholder="$translate(input.placeholder)"
                />
            </div>
        </div>

        <div class="buttons bottom">
            <div class="flex-row">
                <button @click="$emit('close')" class="btn btn-default" v-html="$translate('CANCEL')" />
                <button
                    @click="onClick"
                    class="btn"
                    :class="this.isNotNull ? 'btn-primary' : 'btn-primary-off'"
                    v-html="buttons ? $translate(buttons) : $translate('CONFIRM')"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalInput',
    props: ['options'],
    data: () => ({
        isNotNull: false,
    }),
    computed: {
        title() {
            return (this.options || {}).title
        },
        titleClass() {
            return (this.options || {}).titleClass
        },
        inputs() {
            return (this.options || {}).inputs || []
        },
        buttons() {
            return (this.options || {}).buttons
        },
        error() {
            return (this.options || {}).error
        },
    },
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
        this.$nextTick(_ => {
            const dom = document.getElementsByClassName('modal-input-0')
            if (dom && dom.length > 0) {
                dom[0].focus()
            }
        })
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    methods: {
        onClickButton(btn) {
            if (this.isNotNull) {
                this.$emit('close', this.inputs)
            } else {
                this.$toast.error(this.$translate(this.error))
            }
        },
        onKeydown(event) {
            if (event.key === 'Enter' && this.inputs) {
                const confirmButton = this.inputs[this.inputs.length - 1]
                if (confirmButton) this.onClickButton(confirmButton)
            }
        },
        inputChecker(inputText) {
            if (inputText.length === 0) {
                this.isNotNull = false
            } else {
                this.isNotNull = true
            }

            return inputText
        },
        onClick() {
            if (this.isNotNull) {
                this.$emit('close', this.inputs)
            } else {
                this.$toast.error(this.$translate(this.error) || '값을 입력해주세요')
            }
        },
    },
}
</script>
